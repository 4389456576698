import React from "react";
import styles from "./index.module.scss";
import MetricCard from "./MetricCard";

const Metrics: React.FC = () => {
  const metrics = [
    {
      label: "Total Capacity",
      value: "74.6 kWp",
      icon: "bolt"
    },
    {
      label: "Size",
      value: "766 m²",
      icon: "square_foot"
    },
    {
      label: "Number of Solar Modules",
      value: "1,036 modules",
      icon: "grid_view"
    },
    {
      label: "Annual Energy Production",
      value: "78,000 kWh",
      icon: "power"
    },
    {
      label: "Performance Ratio",
      value: "87%",
      icon: "speed"
    },
    {
      label: "CO2 Reduction per Year",
      value: "45.5 tons",
      icon: "eco"
    },
    {
      label: "Feed-in Tariff",
      value: "0.349 cents / kWh",
      icon: "payments"
    },
    {
      label: "Commissioning Date",
      value: "Year 2009",
      icon: "event"
    },
    {
      label: "Payback Period",
      value: "9.5 years",
      icon: "update"
    }
  ];

  return (
    <div id="metrics" className={styles.container}>
      <div className={styles.header}>
        <h2>Solar Farm Metrics</h2>
      </div>
      <div className={styles.gridContainer}>
        <div className={styles.grid}>
          {metrics.map((metric) => (
            <MetricCard
              key={metric.label}
              label={metric.label}
              value={metric.value}
              icon={metric.icon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Metrics;
