import React, { useEffect } from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import { useLocation } from "react-router-dom";
import { getConfig } from "../../config";
import styles from "./index.module.scss";
import { MlmType } from "../../api";

type LayoutProps = {
  mlmType: MlmType;
}

export const Layout = ({ children, mlmType }: React.PropsWithChildren<LayoutProps>) => {
  const config = getConfig();
  const location = useLocation();

  // Get invite code from URL and save to local storage (if present)
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const newInviteCode = query.get("inviteCode");

    if (newInviteCode) {
      localStorage.setItem(config.inviteCode.storageKey, newInviteCode);
    }
  }, [location]);

  return (
    <div className={styles.page_wrapper}>
      <Header mlmType={mlmType} />
      <div className={styles.page_content}>{children}</div>
      <Footer />
    </div>
  );
};
