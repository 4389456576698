import { Layout } from "../../../components/layout";
import RhinoxMarketplace from "./rhinoxMarketplace";

function RhinoxMarketplacePage() {
  return (
    <Layout mlmType="NFTEAM">
      <RhinoxMarketplace />
    </Layout>
  );
}

export default RhinoxMarketplacePage;
