import { MlmType, WalletType } from "../../api";
import { useAuthContext } from "../../context/AuthContext";
import { Button } from "../button";
import { Modal } from "../Modal";
import styles from "./index.module.scss";
import eternlIcon from "../../assets/icons/wallets/eternl.svg";
import laceIcon from "../../assets/icons/wallets/lace.svg";
import namiIcon from "../../assets/icons/wallets/nami.svg";
import vesprIcon from "../../assets/icons/wallets/vespr-dark.svg";
import tokeoIcon from "../../assets/icons/wallets/tokeo.png";
import { useState } from "react";
import { useToastMessage } from "../../hooks/useToastMessage";
import { getConfig } from "../../config";

type WalletConnectorModalProps = {
  mlmType: MlmType;
  isOpen: boolean;
  onClose: () => void;
};

type ModalState = "connect" | "signup-pending" | "signup-started" | "loading";

/**
 * A modal that allows the user to select between wallets to connect to.
 * Currently only Nami and Eternl are supported.
 */
export const WalletConnectorModal = ({
  mlmType,
  isOpen,
  onClose,
}: WalletConnectorModalProps) => {
  const {
    wallet,
    connectWallet,
    refetchUser,
    installedWallets,
    isLoading,
  } = useAuthContext();
  const toast = useToastMessage();
  const config = getConfig();
  const [ isSignupStarted, setIsSignupStarted ] = useState(false);

  const getModalState = (): ModalState => {
    if (isLoading) return "loading";
    if (wallet && isSignupStarted) return "signup-started";
    if (wallet) return "signup-pending";
    return "connect";
  };

  const onWalletClick = (wallet: WalletType) => async () => {
    try {
      await connectWallet(wallet);
      const user = await refetchUser(mlmType);
      if (user) {
        onClose();
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const onSignUpClick = () => {
    setIsSignupStarted(true);
    const signUpUrl = mlmType === "Kwarxs" ? config.kwarxsSignupUrl : config.nfteamSignupUrl;
    window.open(signUpUrl, "_blank");
  };

  const onCompletedSignupClick = async () => {
    const foundUser = await refetchUser(mlmType);

    if (!foundUser) {
      toast.error(
        "No user found with your wallet address. Please try signing up again."
      );
    } else {
      onClose();
    }
  };

  const renderContent = () => {
    switch (getModalState()) {
      case "connect":
        return (
          <>
            <h2>Connect Wallet</h2>
            {renderWalletButton("Nami", namiIcon, installedWallets.nami)}
            {renderWalletButton("Eternl", eternlIcon, installedWallets.eternl)}
            {renderWalletButton("VESPR", vesprIcon, installedWallets.vespr)}
            {renderWalletButton("Lace", laceIcon, installedWallets.lace)}
            {renderWalletButton(
              "Tokeo",
              tokeoIcon,
              installedWallets.tokeo,
              true
            )}
          </>
        );
      case "signup-pending":
        return (
          <>
            <h2>Create Account</h2>
            <p>
              You'll be directed to a separate signup form. Once you complete the
              signup process, please return here to continue.
            </p>
            <div className={styles.buttonContainer}>
              <Button onClick={onSignUpClick}>Sign Up</Button>
            </div>
          </>
        );
      case "signup-started":
        return (
          <>
            <h2>Create Account</h2>
            <Button onClick={onCompletedSignupClick}>
              I've completed the sign up form
            </Button>
            <Button secondary onClick={onSignUpClick}>
              Return to signup page
            </Button>
          </>
        );
    }
  };

  const renderWalletButton = (
    wallet: WalletType,
    icon: string,
    isInstalled: boolean,
    isRounded?: boolean
  ) => (
    <Button
      onClick={onWalletClick(wallet)}
      disabled={isLoading || !isInstalled}
    >
      <img
        src={icon}
        alt={wallet}
        style={{ borderRadius: isRounded ? "15px" : "0" }}
      />
      <span>Connect with {wallet}</span>
    </Button>
  );

  return (
    <Modal showModal={isOpen && !isLoading} onClose={onClose}>
      <div className={styles.modalContent}>
        {renderContent()}
      </div>
    </Modal>
  );
};

export default WalletConnectorModal;
