import { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import { useCollectionsQuery } from "../../hooks/useNftCollections";
import { Collection } from "../../api/generated/models";
import AddNftsModal from "./AddNftsModal";
import { FaRegSquarePlus } from "react-icons/fa6";
import styles from "./index.module.scss";
import RewardPoolsCell from "./RewardPoolsCell";
import AddRewardPoolsModal from "./AddRewardPoolModal";

type AddNftsState = {
  collectionName: string;
  currencySymbol: string;
  prefixLength: number;
  isModalOpen: boolean;
};

/**
 * A component that displays a table of NFT collections.
 */
const CollectionsTable = () => {
  const { data: collections } = useCollectionsQuery();

  // Use currencySymbol for the 'rewardPools' column as it is just the input to the render
  // function that will display the actual pool details.
  const mappedCollections = collections?.map((col) => ({ ...col, rewardPools: col.currencySymbol }));

  const [ addNftsState, setAddNftsState ] = useState<AddNftsState>({
    collectionName: "",
    currencySymbol: "",
    prefixLength: 0,
    isModalOpen: false,
  });

  const [ addRewardPoolState, setAddRewardPoolState ] = useState({
    collectionCurrencySymbol: "",
    isModalOpen: false,
  });

  const onCloseAddNftsModal = () => {
    setAddNftsState({
      collectionName: "",
      currencySymbol: "",
      prefixLength: 0,
      isModalOpen: false,
    });
  };

  const onCloseAddRewardPoolModal = () => {
    setAddRewardPoolState({
      collectionCurrencySymbol: "",
      isModalOpen: false,
    });
  };

  const renderRemainingCell = ({
    value,
    data: { name, currencySymbol, prefixLength },
  }: ICellRendererParams) => {
    return (
      <div
        className={styles.cell}
        onClick={() =>
          setAddNftsState({
            collectionName: name,
            currencySymbol,
            prefixLength,
            isModalOpen: true,
          })
        }
      >
        {value}
        <FaRegSquarePlus className={styles.icon_add} />
      </div>
    );
  };

  const renderRewardPoolsCell = ({ value }: ICellRendererParams) => {
    return RewardPoolsCell({ collectionCurrencySymbol: value, setAddRewardPoolState });
  };

  const colDefs: ColDef<Collection & { rewardPools: string }>[] = [
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Currency Symbol",
      field: "currencySymbol",
      initialWidth: 180,
    },
    {
      headerName: "Price",
      field: "price",
      initialWidth: 90,
      type: "numericColumn",
    },
    {
      headerName: "APY",
      field: "apy",
      initialWidth: 90,
      type: "numericColumn",
    },
    {
      headerName: "Total",
      field: "total",
      initialWidth: 90,
      type: "numericColumn",
    },
    {
      headerName: "Remaining",
      field: "remaining",
      initialWidth: 100,
      cellRenderer: renderRemainingCell,
      type: "numericColumn",
    },
    {
      headerName: "Deadline",
      field: "deadline",
      initialWidth: 180,
    },
    {
      headerName: "Prefix Length",
      field: "prefixLength",
      initialWidth: 120,
      type: "numericColumn",
    },
    {
      headerName: "Reward Pool UTXOs",
      field: "rewardPools",
      cellRenderer: renderRewardPoolsCell,
      initialWidth: 110,
      type: "numericColumn",
    }
  ];

  return (
    <div className="ag-theme-quartz" style={{ height: "600", width: "100%" }}>
      <AgGridReact
        rowData={mappedCollections}
        columnDefs={colDefs}
        rowHeight={50}
        domLayout="autoHeight"
        suppressDragLeaveHidesColumns
      />
      <AddNftsModal
        isOpen={addNftsState.isModalOpen}
        collectionName={addNftsState.collectionName}
        currencySymbol={addNftsState.currencySymbol}
        prefixLength={addNftsState.prefixLength}
        onClose={onCloseAddNftsModal}
      />
      <AddRewardPoolsModal
        isOpen={addRewardPoolState.isModalOpen}
        collectionCurrencySymbol={addRewardPoolState.collectionCurrencySymbol}
        onClose={onCloseAddRewardPoolModal}
      />
    </div>
  );
};

export default CollectionsTable;
