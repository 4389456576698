import mask from "../../assets/img/pink.gif";
import CollectionCard from "../../components/CollectionCard";
import styles from "./index.module.scss";
import nft1 from "../../assets/img/nft-image1.png";
import { useCollectionsQuery } from "../../hooks/useNftCollections";
import { Spinner } from "../../components/Spinner";
import { getConfig } from "../../config";

const marketText =
  "31600 unique, hand-crafted artworks comprise this Sun Gowds NFT series. Embedded token" +
  " metadata provides fractionalized ownership of a profitable grid-connected solar farm in Germany." +
  " Note that 80% of every sale goes directly into the Sun Gowds DAO treasury.";

const Marketplace = () => {
  const { data: collections, isLoading } = useCollectionsQuery();
  const config = getConfig();

  const renderCollections = () => {
    const now = Date.now();

    return collections
      ?.filter((col) => {
        return (
          col.currencySymbol !== config.rhinoxCurrencySymbol &&
          col.remaining > 0 &&
          new Date(col.deadline).getTime() > now
        );
      })
      .map((col) => {
        return (
          <CollectionCard
            key={`${col.name}${col.currencySymbol}`}
            collection={col}
            imageUrl={nft1}
          />
        );
      });
  };

  return (
    <div className={styles.marketplace__wrapper}>
      <div className={styles.marketplace__container}>
        <div className={styles.marketplace__heading}>
          <div className={styles.marketplace__img}>
            <img src={mask} alt="pink mask" />
          </div>
          <div>
            <div>
              <h3 className={styles.marketplace__title}>Sun Gowds</h3>
              <h4 className={styles.marketplace__subtitle}>By: Kwarxs</h4>
            </div>
            <div>
              <p className={styles.marketplace__text}>{marketText}</p>
            </div>
          </div>
        </div>
        <div className={styles.marketplace__mobile__text}>
          <p>
            <b>Released Feb 14, 2023</b>
          </p>
          <p>{marketText}</p>
        </div>
      </div>
      <div className={styles.nftcard__container}>
        {isLoading ? (
          <Spinner color="grey" />
        ) : (
          <div className={styles.marketplace__nftcard}>
            {renderCollections()}
          </div>
        )}
      </div>
    </div>
  );
};

export default Marketplace;
