import React from "react";
import styles from "./index.module.scss";

interface MetricCardProps {
  label: string;
  value: string;
  icon: string;
}

const MetricCard: React.FC<MetricCardProps> = ({ label, value, icon }) => (
  <div className={styles.card}>

    <div className={styles.label}>{label}</div>
    <div className={styles.heading}>
      <span className={`material-icons-outlined ${styles.icon}`}>
        {icon}
      </span>
      <div className={styles.value}>{value}</div>
    </div>
  </div>
);

export default MetricCard;
