import { Landing } from "./pages/landing";
import { createBrowserRouter } from "react-router-dom";
import MarketplacePage from "./pages/marketplace";
import TermsPage from "./pages/terms";
import YourNftsPage from "./pages/yourNfts";
import AdminPage from "./pages/admin";
import RhinoxMarketplacePage from "./pages/marketplace/rhinox";
import RootLayout from "./RootLayout";

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <Landing />,
        errorElement: <div>404</div>,
      },
      {
        path: "/rhinox",
        element: <RhinoxMarketplacePage />,
      },
      {
        path: "/marketplace",
        element: <MarketplacePage />,
      },
      {
        path: "/marketplace/:id",
        element: <div>NFT</div>,
      },
      {
        path: "your-nfts",
        element: <YourNftsPage />,
      },
      {
        path: "/rewardpool",
        element: <div>Reward pools</div>,
      },
      {
        path: "/treasury",
        element: <div>Treasury</div>,
      },
      {
        path: "/vote",
        element: <div>Voting</div>,
      },
      {
        path: "/reward",
        element: <div>Rewards</div>,
      },
      {
        path: "/admin",
        element: <AdminPage />,
      },
      {
        path: "/terms",
        element: <TermsPage />,
      }
    ],
  },
]);
