import styles from "./index.module.scss";

export const TermsText = () => {
  return (
    <div className={styles.terms__container}>
      <h2>Terms and Conditions (T&Cs) of KXS BAY S.L.</h2>
      <h3>1. <strong>Scope of Application</strong></h3>
      <p>These Terms and Conditions (T&Cs) apply to all services and products offered through the platform of KXS BAY S.L. (hereinafter referred to as "Provider"), particularly the purchase and trade of NFTs (Non-Fungible Tokens) on the Cardano blockchain. By using this platform, the user fully accepts these T&Cs.</p>
      <h3>2. <strong>Disclaimer of Liability</strong></h3>
      <ol>
        <li>
          <strong>No Liability of the Provider:</strong>
          <p>The Provider assumes no liability for any damages or losses arising from the use of the platform, the purchase, or the management of NFTs. The user acts solely at their own risk. This includes, but is not limited to, potential losses from the trading of NFTs, technical issues with the blockchain, or losses due to cyber-attacks.</p>
        </li>
        <li>
          <strong>No Guarantees:</strong>
          <p>The Provider does not guarantee any increase in value or the worthiness of the NFTs. NFTs are speculative digital assets whose value is determined by market forces. The Provider accepts no responsibility for the future development of the NFT market.</p>
        </li>
        <li>
          <strong>No Responsibility for Third Parties:</strong>
          <p>The Provider is not responsible for the actions or omissions of third parties, including platforms used for the trading or storage of NFTs or the Cardano blockchain itself. All transactions are processed decentralized via the blockchain, over which the Provider has no control.</p>
        </li>
      </ol>
      <h3>3. <strong>Use at Own Risk</strong></h3>
      <ol>
        <li>
          <strong>Self-Responsibility:</strong>
          <p>The purchase, trade, or "burning" of NFTs and the use of the platform are carried out at the user's own risk. The Provider makes no promises regarding the functionality or success of these actions. Once acquired, NFTs cannot be deleted but can only be burned as permitted by the blockchain's technical limitations.</p>
        </li>
        <li>
          <strong>Technical Risks:</strong>
          <p>The Provider is not responsible for technical problems, errors, or interruptions of the blockchain or the platform that may affect the purchase or use of NFTs.</p>
        </li>
      </ol>
      <h3>4. <strong>User Responsibility</strong></h3>
      <ol>
        <li>
          <strong>Minimum Age Requirement:</strong>
          <p>The user must be at least 18 years old to use the Provider's services. By using the platform, the user confirms that they meet this minimum age requirement.</p>
        </li>
        <li>
          <strong>Self-Management:</strong>
          <p>The user is responsible for managing their crypto wallet and ensuring that they meet the technical requirements to receive and manage NFTs and ADA tokens.</p>
        </li>
      </ol>
      <h3>5. <strong>Purchase and Sale of NFTs</strong></h3>
      <ol>
        <li>
          <strong>Payment Method:</strong>
          <p>The purchase of NFTs is made exclusively through cryptocurrencies, particularly ADA tokens. The Provider does not accept payments in fiat currencies.</p>
        </li>
        <li>
          <strong>No Right of Withdrawal:</strong>
          <p>The sale of NFTs is final, and withdrawal from the purchase or return of an NFT is excluded. NFTs cannot be deleted but can only be burned according to the blockchain's technical capabilities.</p>
        </li>
      </ol>
      <h3>6. <strong>Modification of T&Cs</strong></h3>
      <p>The Provider reserves the right to modify these T&Cs at any time. It is the user's responsibility to stay informed about any changes. Continued use of the platform after modifications will be considered acceptance of the revised terms.</p>
      <h3>7. <strong>Applicable Law and Jurisdiction</strong></h3>
      <p>All legal disputes related to these T&Cs shall be governed by the law of the Provider's registered office in Spain, excluding the UN Convention on Contracts for the International Sale of Goods. The place of jurisdiction, where legally permissible, is the Provider's registered office.</p>
      <h3>8. <strong>Severability Clause</strong></h3>
      <p>Should any provision of these T&Cs be invalid or unenforceable, the remaining provisions shall remain unaffected. The invalid provision shall be replaced by one that most closely approximates the economic purpose of the invalid provision.</p>
    </div>
  );
};
