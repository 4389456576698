/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 */
import type {
  Collection,
  CreateCollection,
  GYAddress,
  GYTxId,
  MetadataObj,
  PostAccountsWallet200,
  PostAccountsWalletParams,
  PostNftsBuy200,
  PostNftsBuyBody,
  PostNftsCreateNftBodyItem,
  PostRewards200,
  PostRewardsBody,
  PostRewardsWithdraw200,
  PostRewardsWithdrawBody,
  PostTxSubmitBody,
  RewardPool,
  RewardPoolNft
} from './models'
import { customFetch } from '../custom-fetch';


export type getRewardsAssetResponse = {
  data: RewardPool[];
  status: number;
}

export const getGetRewardsAssetUrl = (asset: string,) => {


  return `https://kwarxs-preview.staging.mlabs.city/rewards/${asset}`
}

export const getRewardsAsset = async (asset: string, options?: RequestInit): Promise<getRewardsAssetResponse> => {
  
  return customFetch<Promise<getRewardsAssetResponse>>(getGetRewardsAssetUrl(asset),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



export type postRewardsResponse = {
  data: PostRewards200;
  status: number;
}

export const getPostRewardsUrl = () => {


  return `https://kwarxs-preview.staging.mlabs.city/rewards`
}

export const postRewards = async (postRewardsBody: PostRewardsBody, options?: RequestInit): Promise<postRewardsResponse> => {
  
  return customFetch<Promise<postRewardsResponse>>(getPostRewardsUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      postRewardsBody,)
  }
);}



export type postRewardsWithdrawResponse = {
  data: PostRewardsWithdraw200;
  status: number;
}

export const getPostRewardsWithdrawUrl = () => {


  return `https://kwarxs-preview.staging.mlabs.city/rewards/withdraw`
}

export const postRewardsWithdraw = async (postRewardsWithdrawBody: PostRewardsWithdrawBody, options?: RequestInit): Promise<postRewardsWithdrawResponse> => {
  
  return customFetch<Promise<postRewardsWithdrawResponse>>(getPostRewardsWithdrawUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      postRewardsWithdrawBody,)
  }
);}



export type getRewardsNftsResponse = {
  data: RewardPoolNft[];
  status: number;
}

export const getGetRewardsNftsUrl = () => {


  return `https://kwarxs-preview.staging.mlabs.city/rewards/nfts`
}

export const getRewardsNfts = async ( options?: RequestInit): Promise<getRewardsNftsResponse> => {
  
  return customFetch<Promise<getRewardsNftsResponse>>(getGetRewardsNftsUrl(),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



export type postAccountsWalletResponse = {
  data: PostAccountsWallet200;
  status: number;
}

export const getPostAccountsWalletUrl = (params?: PostAccountsWalletParams,) => {
  const normalizedParams = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, value]) => {
    
    if (value !== undefined) {
      normalizedParams.append(key, value === null ? 'null' : value.toString())
    }
  });

  return normalizedParams.size ? `https://kwarxs-preview.staging.mlabs.city/accounts/wallet?${normalizedParams.toString()}` : `https://kwarxs-preview.staging.mlabs.city/accounts/wallet`
}

export const postAccountsWallet = async (gYAddress: GYAddress[],
    params?: PostAccountsWalletParams, options?: RequestInit): Promise<postAccountsWalletResponse> => {
  
  return customFetch<Promise<postAccountsWalletResponse>>(getPostAccountsWalletUrl(params),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      gYAddress,)
  }
);}



export type getNftsResponse = {
  data: Collection[];
  status: number;
}

export const getGetNftsUrl = () => {


  return `https://kwarxs-preview.staging.mlabs.city/nfts`
}

export const getNfts = async ( options?: RequestInit): Promise<getNftsResponse> => {
  
  return customFetch<Promise<getNftsResponse>>(getGetNftsUrl(),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



export type postNftsBuyResponse = {
  data: PostNftsBuy200;
  status: number;
}

export const getPostNftsBuyUrl = () => {


  return `https://kwarxs-preview.staging.mlabs.city/nfts/buy`
}

export const postNftsBuy = async (postNftsBuyBody: PostNftsBuyBody, options?: RequestInit): Promise<postNftsBuyResponse> => {
  
  return customFetch<Promise<postNftsBuyResponse>>(getPostNftsBuyUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      postNftsBuyBody,)
  }
);}



export type postNftsCreateResponse = {
  data: unknown[];
  status: number;
}

export const getPostNftsCreateUrl = () => {


  return `https://kwarxs-preview.staging.mlabs.city/nfts/create`
}

export const postNftsCreate = async (createCollection: CreateCollection[], options?: RequestInit): Promise<postNftsCreateResponse> => {
  
  return customFetch<Promise<postNftsCreateResponse>>(getPostNftsCreateUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      createCollection,)
  }
);}



export type postNftsCreateNftResponse = {
  data: unknown[];
  status: number;
}

export const getPostNftsCreateNftUrl = () => {


  return `https://kwarxs-preview.staging.mlabs.city/nfts/create-nft`
}

export const postNftsCreateNft = async (postNftsCreateNftBodyItem: PostNftsCreateNftBodyItem[], options?: RequestInit): Promise<postNftsCreateNftResponse> => {
  
  return customFetch<Promise<postNftsCreateNftResponse>>(getPostNftsCreateNftUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      postNftsCreateNftBodyItem,)
  }
);}



export type postTxSubmitResponse = {
  data: GYTxId;
  status: number;
}

export const getPostTxSubmitUrl = () => {


  return `https://kwarxs-preview.staging.mlabs.city/tx/submit`
}

export const postTxSubmit = async (postTxSubmitBody: PostTxSubmitBody, options?: RequestInit): Promise<postTxSubmitResponse> => {
  
  return customFetch<Promise<postTxSubmitResponse>>(getPostTxSubmitUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      postTxSubmitBody,)
  }
);}



export type getMetadataPolicyIdTokenNameResponse = {
  data: MetadataObj;
  status: number;
}

export const getGetMetadataPolicyIdTokenNameUrl = (policyId: string,
    tokenName: string,) => {


  return `https://kwarxs-preview.staging.mlabs.city/metadata/${policyId}/${tokenName}`
}

export const getMetadataPolicyIdTokenName = async (policyId: string,
    tokenName: string, options?: RequestInit): Promise<getMetadataPolicyIdTokenNameResponse> => {
  
  return customFetch<Promise<getMetadataPolicyIdTokenNameResponse>>(getGetMetadataPolicyIdTokenNameUrl(policyId,tokenName),
  {      
    ...options,
    method: 'GET'
    
    
  }
);}



