import React, {
  createContext,
  useState,
  useMemo,
  PropsWithChildren,
  useEffect,
} from "react";
import { MlmType, Wallet, WalletType, useSdkContext } from "./SdkContext";
import { UserInfo } from "../api/generated/models";
import { useQuery } from "@tanstack/react-query";

interface AuthContextType {
  nfteamUser: UserInfo | null;
  kwarxsUser: UserInfo | null;
  wallet: Wallet | null;
  isLoading: boolean;
  connectWallet: (walletType: WalletType) => Promise<void>;
  disconnectWallet: () => void;
  refetchUser: (mlmType: MlmType) => Promise<UserInfo | null>;
  installedWallets: {
    nami: boolean;
    eternl: boolean;
    vespr: boolean;
    lace: boolean;
    tokeo: boolean;
  };
}

export const AuthContext = createContext<AuthContextType>({
  nfteamUser: null,
  kwarxsUser: null,
  wallet: null,
  isLoading: false,
  connectWallet: async () => {},
  disconnectWallet: async () => {},
  refetchUser: async () => null,
  installedWallets: {
    nami: false,
    eternl: false,
    vespr: false,
    lace: false,
    tokeo: false,
  },
});

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const sdk = useSdkContext();
  const [ wallet, setWallet ] = useState<Wallet | null>(null);
  const [ installedWallets, setInstalledWallets ] = useState<
    AuthContextType["installedWallets"]
  >({
    nami: false,
    eternl: false,
    vespr: false,
    lace: false,
    tokeo: false,
  });
  const [ isLoading, setIsLoading ] = useState<boolean>(false);

  const nfteamUserQuery = useQuery({
    queryKey: [ "user", "NFTEAM" ],
    queryFn: () => {
      return withErrorHandling("nfteamUserQuery", () =>
        sdk.fetchUserForConnectedWallet("NFTEAM")
      );
    },
    enabled: !!wallet,
  });

  const kwarxsUserQuery = useQuery({
    queryKey: [ "user", "Kwarxs" ],
    queryFn: () => {
      return withErrorHandling("kwarxsUserQuery", () =>
        sdk.fetchUserForConnectedWallet("Kwarxs")
      );
    },
    enabled: !!wallet,
  });

  const withErrorHandling = async <T,>(
    label: string,
    fn: () => Promise<T>,
    onError?: (e: any) => void
  ) => {
    setIsLoading(true);
    try {
      const result = await fn();
      return result;
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log(label, e);
      onError?.(e);
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const connectWallet = async (walletType: WalletType) => {
    return withErrorHandling(
      "connectWallet",
      async () => {
        await sdk.connectToWallet(walletType);

        if (!sdk.connectedWallet) {
          throw new Error("Could not connect to wallet. Please try again.");
        }

        setWallet(sdk.connectedWallet || null);
      },
      (e) => {
        setWallet(null);
      }
    );
  };

  const disconnectWallet = async () => {
    return withErrorHandling("disconnectWallet", async () => {
      sdk.disconnectWallet();
      setWallet(null);
    });
  };

  const refetchUser = async (mlmType: MlmType): Promise<UserInfo | null> => {
    const { data } = await (mlmType === "NFTEAM"
      ? nfteamUserQuery
      : kwarxsUserQuery
    ).refetch();
    return data ?? null;
  };

  const rehydrateConnectionState = async () => {
    return withErrorHandling("rehydrateConnectionState", async () => {
      await sdk.rehydrateConnectionState();
      setWallet(sdk.connectedWallet || null);
    });
  };

  useEffect(() => {
    setInstalledWallets(sdk.getWalletAvailability());
    rehydrateConnectionState();
  }, []);

  const contextValue = useMemo(
    () => ({
      nfteamUser: nfteamUserQuery.data || null,
      kwarxsUser: kwarxsUserQuery.data || null,
      wallet,
      isLoading,
      connectWallet,
      disconnectWallet,
      installedWallets,
      refetchUser,
    }),
    [
      nfteamUserQuery.data,
      kwarxsUserQuery.data,
      wallet,
      isLoading,
      connectWallet,
      disconnectWallet,
      installedWallets,
      refetchUser,
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuthContext = () => React.useContext(AuthContext);
