import { Layout } from "../../components/layout";
import { Hero } from "./hero";
import { SunGowds } from "./sunGowds";
import MembershipBenefits from "./membershipBenefits";
import Faq from "./q&a";
import Metrics from "./metrics";

export const Landing = () => {
  return (
    <Layout mlmType="Kwarxs">
      <div className="container">
        <Hero />
        <MembershipBenefits />
        <SunGowds />
        <Metrics />
        <Faq />
      </div>
    </Layout>
  );
};
