import { Layout } from "../../components/layout";
import Marketplace from "./marketplace";

function MarketplacePage() {
  return (
    <Layout mlmType="Kwarxs">
      <Marketplace />
    </Layout>
  );
}

export default MarketplacePage;
