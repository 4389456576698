import { useSearchParams } from "react-router-dom";
import { Layout } from "../../components/layout";
import UserCollections from "./userCollections";
import UserNfts from "./userNfts";

function YourNftsPage() {
  const [searchParams] = useSearchParams();
  const currencySymbol = searchParams.get("cs");
  const counter = searchParams.get("counter");

  return (
    <Layout mlmType="Kwarxs">
      {currencySymbol && counter ? (
        <UserNfts currencySymbol={currencySymbol} counter={parseInt(counter)} />
      ) : (
        <UserCollections />
      )}
    </Layout>
  );
}

export default YourNftsPage;
