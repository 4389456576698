import React, { createContext, PropsWithChildren } from "react";
import { Kwarxs } from "../api";
import { getConfig } from "../config";

export * from "../api/types";
export * from "../api/cardano-types";

// We just use a static instance of the SDK for now. But this could later
// be replaced with a dynamic SDK instance that differs based on the
// the environment.
const sdk = new Kwarxs(getConfig().cardanoNetwork);

export const SdkContext = createContext<Kwarxs>(sdk);

/**
 * Initializes the Kwarxs SDK and provides it to the rest of the app
 */
export const SdkProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <SdkContext.Provider
      value={sdk}
    >
      {children}
    </SdkContext.Provider>
  );
};

export const useSdkContext = () => React.useContext(SdkContext);
