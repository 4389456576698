import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BuyNftParams, Nft, useSdkContext } from "../context/SdkContext";
import { Collection, CreateCollection } from "../api/generated/models";
import { mkNextNfts, prefixLength } from "../utils/nft";

export const collectionsQueryKeys = {
  collections: "collections",
};

const queryStaleTime = 1000 * 30; // 30 seconds

export const useCollectionsQuery = () => {
  const sdk = useSdkContext();
  return useQuery({
    queryKey: [collectionsQueryKeys.collections],
    staleTime: queryStaleTime,
    queryFn: sdk.getCollections,
  });
};

export const useCollectionQuery = ({
  currencySymbol,
}: {
  currencySymbol: string;
}) => {
  const collectionsQuery = useCollectionsQuery();
  return useQuery({
    queryKey: [ collectionsQueryKeys.collections, currencySymbol ],
    staleTime: queryStaleTime,
    queryFn: async () => {
      const collections = collectionsQuery.data;
      return collections?.find(
        (collection) => collection.currencySymbol === currencySymbol
      );
    },
    enabled: !!collectionsQuery.data,
  });
};

type AddCollectionsMutationParams = Omit<
  CreateCollection,
  "prefixLength" | "total" | "remaining"
>;

export const useAddCollectionMutation = () => {
  const sdk = useSdkContext();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: AddCollectionsMutationParams) => {
      const collection = {
        ...params,
        prefixLength,
        total: 0,
        remaining: 0,
      };

      return sdk.addCollections([collection]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [collectionsQueryKeys.collections],
      });
    },
  });
};

type AddNftsMutationParams = {
  collection: Collection;
  image: string;
  mediaType: string;
  description?: string;
  quantity: number;
};

export const useAddNftsMutation = () => {
  const sdk = useSdkContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      collection,
      image,
      mediaType,
      description,
      quantity,
    }: AddNftsMutationParams) => {
      const nfts = mkNextNfts({
        collection,
        image,
        mediaType,
        description: description ?? "",
        quantity,
      });

      return sdk.addNft(nfts);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [collectionsQueryKeys.collections],
      });
    },
  });
};

export const useBuyNftMutation = () => {
  const sdk = useSdkContext();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ currencySymbol, mlmType }: BuyNftParams) => {
      return sdk.buyNft({
        currencySymbol,
        mlmType,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [collectionsQueryKeys.collections],
      });
    },
  });
};

export type NftWithCollection = Nft & {
  collection: Collection;
};

export const useUserNftsQuery = () => {
  const sdk = useSdkContext();
  const collectionsQuery = useCollectionsQuery();

  return useQuery({
    queryKey: ["userNfts"],
    staleTime: queryStaleTime,
    queryFn: () => {
      const collections = collectionsQuery.data;
      if (!collections) return [];

      const promises = collections.map(async ({ currencySymbol }) => {
        return sdk.getUsersNfts(currencySymbol);
      });

      return Promise.all(promises).then((results) => {
        const nfts = results.flat();
        const collectionsMap = collections?.reduce((acc, collection) => {
          return { ...acc, [collection.currencySymbol]: collection };
        }, {} as Record<string, Collection>);

        return nfts.map((nft) => {
          const collection = collectionsMap?.[nft.currencySymbol];
          return {
            ...nft,
            collection,
          } as NftWithCollection;
        });
      });
    },
    enabled: !!collectionsQuery.data,
  });
};
