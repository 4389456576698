import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import logo from "../../../assets/img/logo.svg";
import menu from "../../../assets/icons/menu.svg";
import { ReactSVG } from "react-svg";
import { Button } from "../../button";
import close from "../../../assets/icons/close.svg";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../Spinner";
import { getConfig } from "../../../config";
import WalletConnectorModal from "../../WalletConnectorModal";
import { MlmType } from "../../../api";
import defaultWalletIcon from "../../../assets/img/wallet.svg";
import eternlIcon from "../../../assets/icons/wallets/eternl.svg";
import laceIcon from "../../../assets/icons/wallets/lace.svg";
import namiIcon from "../../../assets/icons/wallets/nami.svg";
import vesprIcon from "../../../assets/icons/wallets/vespr-white.svg";
import tokeoIcon from "../../../assets/icons/wallets/tokeo.png";

type HeaderProps = {
  mlmType: MlmType;
};

export const Header = ({ mlmType }: HeaderProps) => {
  const config = getConfig();
  const [ showNav, setShowNav ] = useState(false);
  const [ showWalletConnector, setShowWalletConnector ] = useState(false);
  const [ showWalletMenu, setShowWalletMenu ] = useState(false);
  const walletMenuRef = useRef<HTMLDivElement>(null);
  const { wallet, nfteamUser, kwarxsUser, disconnectWallet, isLoading } =
    useAuthContext();

  const showSignUp =
    (mlmType === "NFTEAM" && !nfteamUser) ||
    (mlmType === "Kwarxs" && !kwarxsUser);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        walletMenuRef.current &&
        !walletMenuRef.current.contains(event.target as Node)
      ) {
        setShowWalletMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getWalletIcon = () => {
    switch (wallet?.walletType) {
      case "Nami":
        return namiIcon;
      case "Eternl":
        return eternlIcon;
      case "Lace":
        return laceIcon;
      case "VESPR":
        return vesprIcon;
      case "Tokeo":
        return tokeoIcon;
      default:
        return defaultWalletIcon;
    }
  };

  const onDisconnect = async () => {
    await disconnectWallet();
    setShowWalletMenu(false);
  };

  const renderButtons = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (!wallet) {
      return (
        <Button onClick={() => setShowWalletConnector(true)}>
          Connect to Wallet ({config.cardanoNetwork})
        </Button>
      );
    } else {
      return (
        <>
          <div className={styles.walletContainer} ref={walletMenuRef}>
            <div
              className={styles.wallet}
              onClick={() => setShowWalletMenu(!showWalletMenu)}
            >
              <ReactSVG src={getWalletIcon()} />
              <span>
                {wallet.bech32.slice(0, 6)}...{wallet.bech32.slice(-6)}
              </span>
            </div>
            {showWalletMenu && (
              <div className={styles.walletMenu}>
                <button
                  className={styles.disconnectButton}
                  onClick={onDisconnect}
                >
                  Disconnect
                </button>
              </div>
            )}
          </div>
          {showSignUp && (
            <Button onClick={() => setShowWalletConnector(true)}>
              Sign Up
            </Button>
          )}
        </>
      );
    }
  };

  return (
    <>
      <div className={styles.background}>
        <div className={styles.container}>
          <div onClick={() => setShowNav(!showNav)} className={styles.menu}>
            <img src={menu} alt="menu" />
          </div>
          <a href="/" className={styles.logo}>
            <img src={logo} alt="KWARXS" />
          </a>
          <div className={styles.actions}>{renderButtons()}</div>
        </div>
        {showNav && (
          <div className={styles.nav__container}>
            <div onClick={() => setShowNav(false)} className={styles.close}>
              <img src={close} alt="close" />
            </div>
            <div className={styles.nav__heading}>
              <p>dashboard</p>
            </div>
            <Link to="/">
              <Button className={styles.nav_button} tertiary>
                Kwarxs
              </Button>
            </Link>
            <Link to="/rhinox">
              <Button className={styles.nav_button} tertiary>
                RHINOX
              </Button>
            </Link>
            <Link to="/marketplace">
              <Button className={styles.nav_button} tertiary>
                Sun Gowds
              </Button>
            </Link>
            {wallet && (
              <Link to="/your-nfts">
                <Button className={styles.nav_button} tertiary>
                  Your NFTs
                </Button>
              </Link>
            )}
            <Link to="/marketplace">
              <Button>Join DAO</Button>
            </Link>
          </div>
        )}
      </div>
      <WalletConnectorModal
        mlmType={mlmType}
        isOpen={showWalletConnector}
        onClose={() => setShowWalletConnector(false)}
      />
    </>
  );
};
