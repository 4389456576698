import { TxHash, Network, Data, getAddressDetails } from "lucid-cardano";
import {
  BuyNftParams,
  Nft,
  Wallet,
  WithdrawRewardParams,
  WalletType,
  UserCancelledError,
  WalletNotConnectedError,
  DefaultRequestParams,
  GetRewardPoolsByAssetParams,
  CreateRewardPoolParams,
  RewardPoolWithUTxO,
  MlmType,
} from "./types";
import {
  getMetadataPolicyIdTokenName,
  getNfts,
  getRewardsAsset,
  getRewardsNfts,
  postAccountsWallet,
  postNftsBuy,
  postNftsCreate,
  postNftsCreateNft,
  postRewards,
  postRewardsWithdraw,
  postTxSubmit,
} from "./generated/client";
import {
  Collection,
  CreateCollection,
  GYValue,
  PostNftsCreateNftBodyItem,
  RewardPoolNft,
  UserInfo,
} from "./generated/models";
import { mkGYAssetClass } from "./utils";

const storageKeys = {
  kwarxsUser: "kwarxs-user",
  nfteamUser: "nfteam-user",
  wallet: "wallet",
};

export * from "./types";

export class Kwarxs {
  private network: Network | undefined;
  private _connectedWallet!: Wallet | undefined;
  private _nfteamUser: UserInfo | undefined;
  private _kwarxsUser: UserInfo | undefined;

  constructor(network: Network) {
    this.network = network;
  }

  public get connectedWallet(): Wallet | undefined {
    return this._connectedWallet;
  }

  public get nfteamUser(): UserInfo | undefined {
    return this._nfteamUser;
  }

  public get kwarxsUser(): UserInfo | undefined {
    return this._kwarxsUser;
  }

  /** Disconnect user's wallet
   */
  disconnectWallet = (): void => {
    this._connectedWallet = undefined;
    this._nfteamUser = undefined;
    this._kwarxsUser = undefined;

    localStorage.removeItem(storageKeys.wallet);
    localStorage.removeItem(storageKeys.nfteamUser);
    localStorage.removeItem(storageKeys.kwarxsUser);
  };

  /** Get the first collateral UTxO of the user's wallet
   * @returns Cbor encoded TxOutRef
   */
  private getWalletCollateral = async () => {
    if (!this.connectedWallet) throw new WalletNotConnectedError();

    const walletApi = this.connectedWallet.api;

    const getCollateral =
      walletApi.getCollateral ?? walletApi.experimental.getCollateral;
    const collateralUtxos = await getCollateral();

    if (collateralUtxos.length === 0) {
      throw new Error("No collateral set for the connected wallet");
    }

    return collateralUtxos[0];
  };

  /** Get the default request params required by the backend for generating transactions
   * @returns Default request params
   */
  private getDefaultRequestParams = async (mlmType: MlmType): Promise<DefaultRequestParams> => {
    if (!this.connectedWallet) throw new WalletNotConnectedError();

    const addresses = await this.connectedWallet.api.getUsedAddresses();
    const collateral = await this.getWalletCollateral();

    const user = mlmType === "NFTEAM" ? this.nfteamUser : this.kwarxsUser;

    return {
      addresses,
      change: user?.walletAddress.hex ?? this.connectedWallet?.hex,
      checkCollateral: true,
      collateral,
    };
  };

  private signAndSubmitTx = async (unsignedTx: string): Promise<TxHash> => {
    try {
      if (!this.connectedWallet) throw new WalletNotConnectedError();

      const witnesses = await this.connectedWallet.api.signTx(unsignedTx, true);

      const res = await postTxSubmit({
        unsignedTx,
        witnesses,
      });

      return res.data;
    } catch (e: any) {
      if (e.code === 2) {
        throw new UserCancelledError(e.info);
      }
      throw new Error(JSON.stringify(e));
    }
  };

  private isWalletAvailable = (walletType: WalletType): boolean => {
    if (!window.cardano) {
      return false;
    }
    return Object.keys(window.cardano).includes(walletType.toLowerCase());
  };

  /**
   * Returns a list of available wallets and their availability status
   */
  getWalletAvailability= () => {
    return {
      nami: this.isWalletAvailable("Nami"),
      eternl: this.isWalletAvailable("Eternl"),
      vespr: this.isWalletAvailable("VESPR"),
      lace: this.isWalletAvailable("Lace"),
      tokeo: this.isWalletAvailable("Tokeo"),
    };
  };

  /**
   * Connects the specified user wallet to the dapp
   */
  connectToWallet = async (walletType: WalletType): Promise<void> => {
    try {
      const walletNamespace = window.cardano?.[walletType.toLowerCase()];

      if (!walletNamespace) {
        throw new Error(`${walletType} wallet not found`);
      }

      const wallet = await walletNamespace.enable();

      const ownWalletAddresses = await wallet.getUsedAddresses();
      const ownWalletAddress = ownWalletAddresses[0];
      const addressDetails = getAddressDetails(ownWalletAddress);

      const walletNetworkId = await wallet.getNetworkId();
      const expectedNetworkId = this.network === "Mainnet" ? 1 : 0;

      if (walletNetworkId !== expectedNetworkId) {
        throw new Error(
          `Your wallet is connected to the wrong network. Please connect to ${this.network}.`
        );
      }

      this._connectedWallet = {
        walletType,
        pkh: addressDetails.paymentCredential!.hash,
        skh: addressDetails.stakeCredential?.hash,
        bech32: addressDetails.address.bech32,
        hex: addressDetails.address.hex,
        api: wallet,
      };

      localStorage.setItem(storageKeys.wallet, JSON.stringify(this._connectedWallet));
    } catch (e: any) {
      localStorage.removeItem(storageKeys.wallet);
      throw new Error(e.message);
    }
  };

  private getUserForConnectedWallet = async (
    mlmType: MlmType
  ): Promise<UserInfo | undefined> => {
    const usedAddresses = await this.connectedWallet?.api.getUsedAddresses();

    if (!usedAddresses) {
      throw new WalletNotConnectedError();
    }

    const res = await postAccountsWallet(usedAddresses, {
      nfteam: mlmType === "NFTEAM",
    });

    if (res.status === 200) {
      return {
        accountId: res.data.accountId,
        walletAddress: {
          hex: res.data.walletAddress,
          bech32: getAddressDetails(res.data.walletAddress).address.bech32,
        },
      };
    }
    if (res.status === 404) return undefined;

    throw new Error(`Failed to fetch user info: ${res.data}`);
  };

  fetchUserForConnectedWallet = async (mlmType: MlmType): Promise<UserInfo | null> => {
    if (!this.connectedWallet) throw new WalletNotConnectedError();

    const storageKey = this.getStorageKey(mlmType);
    const userField = this.getUserField(mlmType);

    // TODO: Remove dummy Kwarxs user once Kwarxs MLM integration is working correctly
    const foundUser = mlmType === "NFTEAM" ? await this.getUserForConnectedWallet(mlmType) : {
      accountId: 0,
      walletAddress: {
        hex: this.connectedWallet.hex,
        bech32: this.connectedWallet.bech32,
      },
    };

    if (!foundUser) {
      localStorage.removeItem(storageKey);
      this[userField] = undefined;
      return null;
    }

    localStorage.setItem(storageKey, JSON.stringify(foundUser));
    this[userField] = foundUser;

    return foundUser;
  };

  /**
   * Fetches wallet and user info from local storage, verifies that this data
   * is still valid, and updates the connection state accordingly.
   */
  rehydrateConnectionState = async () => {
    // eslint-disable-next-line
    console.log("Rehydrating connection state");
    try {
      const storedWallet = localStorage.getItem(storageKeys.wallet);

      if (!storedWallet) {
        throw new Error();
      }

      const wallet: Wallet = JSON.parse(storedWallet);

      await this.connectToWallet(wallet.walletType);

      const connectedWallet = this._connectedWallet;

      if (!connectedWallet) {
        throw new Error();
      }

      await this.rehydrateUserState("NFTEAM");
      await this.rehydrateUserState("Kwarxs");

      this._connectedWallet = connectedWallet;
    } catch (error) {
      // If anything fails, reset everything to be safe
      localStorage.removeItem(storageKeys.wallet);
      localStorage.removeItem(storageKeys.nfteamUser);
      localStorage.removeItem(storageKeys.kwarxsUser);
      this._connectedWallet = undefined;
      this._nfteamUser = undefined;
      this._kwarxsUser = undefined;
    }
  };

  /**
   * Adds new NFT collections to the marketplace.
   * @param collections List of collections to add
   */
  addCollections = async (collections: CreateCollection[]): Promise<void> => {
    await postNftsCreate(collections);
  };

  /**
   * Fetches all NFT collections available in the marketplace.
   * @returns List of NFT collections
   */
  getCollections = async (): Promise<Collection[]> => {
    const res = await getNfts();

    if (res.status !== 200) {
      throw new Error(`Failed to fetch NFT collections: ${res.data}`);
    }

    return res.data;
  };

  /**
   * Adds new NFTs to the marketplace.
   * @param nfts List of NFTs to add
   */
  addNft = async (nfts: PostNftsCreateNftBodyItem[]): Promise<void> => {
    await postNftsCreateNft(nfts);
  };

  /**
   * Buys an NFT using the connected wallet.
   *
   * @param {BuyNftParams} params - The parameters for buying the NFT.
   * @param {string} params.currencySymbol - The currency symbol of the NFT to be bought.
   * @returns {Promise<TxHash>} - A promise that resolves to the transaction hash of the submitted transaction.
   * @throws {Error} - Throws an error if the user wallet is not connected.
   * @throws {UserCancelledError} - Throws an error if the user cancels the transaction signing.
   * @throws {Error} - Throws an error for any other failure during the transaction process.
   */
  buyNft = async ({ currencySymbol, mlmType }: BuyNftParams): Promise<TxHash> => {
    const defaultParams = await this.getDefaultRequestParams(mlmType);
    const response = await postNftsBuy({
      currencySymbol,
      ...defaultParams,
    });

    if (response.status !== 200) {
      throw new Error(`Failed to buy NFT: ${response.data}`);
    }

    return this.signAndSubmitTx(response.data.unsignedTx);
  };

  /** Get user's NFTs and it's metadata
   * @param currencySymbol Currency symbol of NFT collection
   * @returns List of user's NFTs
   */
  getUsersNfts = async (currencySymbol: string): Promise<Nft[]> => {
    if (!this.connectedWallet) throw new WalletNotConnectedError();

    const walletBalance = await this.connectedWallet.api.getBalance();

    const walletBalanceData = Data.from(walletBalance);

    if (!Array.isArray(walletBalanceData)) {
      return [];
    }

    const assetsMap = walletBalanceData[1];

    if (!(assetsMap instanceof Map)) {
      return [];
    }

    const res: Nft[] = [];

    for (const [ cs, tokens ] of assetsMap) {
      if (cs !== currencySymbol || !(tokens instanceof Map)) {
        continue;
      }

      for (const [tn] of tokens) {
        if (typeof tn !== "string") continue;

        const response = await getMetadataPolicyIdTokenName(cs, tn);

        if (response.status === 200 && response.data) {
          res.push({
            currencySymbol: cs,
            tokenName: tn,
            metadata: response.data,
          });
        }
      }
    }
    return res;
  };

  /** Create reward pools
   * @returns Hash of submitted transaction
   */
  createRewardPools = async (params: CreateRewardPoolParams): Promise<TxHash> => {
    const defaultParams = await this.getDefaultRequestParams("Kwarxs");
    const res = await postRewards({
      cpCounter: params.counter,
      cpRewardNftsPerPool: params.dividendsPerPool,
      cpRewardNftCs: params.nftCs,
      cpPoolCount: params.poolCount,
      cpPoolTn: params.poolTn,
      cpRewardPerNft: params.rewardValue,
      ...defaultParams,
    });

    return this.signAndSubmitTx(res.data.unsignedTx);
  };

  async getRewardNfts(): Promise<RewardPoolNft[]> {
    const res = await getRewardsNfts();
    return res.data;
  }

  /** Withdraw part of reward from the pool of rewards
   * @param param Reward to withdraw with list of NFTs to remint
   * @returns Hash of submitted transaction
   */
  async withdrawReward(params: WithdrawRewardParams): Promise<TxHash> {
    const defaultParams = await this.getDefaultRequestParams("Kwarxs");

    const response = await postRewardsWithdraw({
      wpCollection: params.collectionPolicyId,
      wpRewardNfts: params.nftTokenNames,
      wpPoolRef: params.pool.poolUTxO[0] as string,
      ...defaultParams,
    });

    if (response.status !== 200) {
      throw new Error(`Failed to withdraw reward: ${response.data}`);
    }

    return this.signAndSubmitTx(response.data.unsignedTx);
  }

  /** List available pools of rewards for the given asset
   */
  getRewardPoolsByAsset = async (
    params: GetRewardPoolsByAssetParams
  ): Promise<RewardPoolWithUTxO[]> =>{
    const assetClass = mkGYAssetClass(params);
    const res = await getRewardsAsset(assetClass);

    if (res.status !== 200) {
      throw new Error(`Failed to fetch reward pools: ${res.data}`);
    }

    return res.data as RewardPoolWithUTxO[];
  };

  public static extractClaimableAmount = (
    pool: RewardPoolWithUTxO,
    nftQty = 1
  ): GYValue => {
    // Get the datum from the UTXO (third element in poolUTxO array)
    const datum = pool.poolUTxO[2];

    const result: GYValue = {};

    const valueMap = datum!.fields[3].map;

    for (const outerEntry of valueMap) {
      // The outer key is the policy ID (empty string means ADA)
      const policyId = outerEntry.k.bytes;

      // Process inner map (token names to amounts)
      const innerMap = outerEntry.v.map;
      for (const innerEntry of innerMap) {
        // The inner key is the asset name
        const assetName = innerEntry.k.bytes;
        const amount = innerEntry.v.int;

        // Create the asset class key
        const assetClass =
          policyId === "" && assetName === ""
            ? "lovelace" // Special case for ADA
            : `${policyId}${assetName ? "." + assetName : ""}`;

        result[assetClass] = amount * nftQty;
      }
    }

    return result;
  };

  private rehydrateUserState = async (mlmType: MlmType) => {
    if (!this.connectedWallet) {
      throw new Error("No wallet connected");
    }

    const storageKey = this.getStorageKey(mlmType);
    const userField = this.getUserField(mlmType);
    const storedUser = localStorage.getItem(storageKey);

    if (!storedUser) {
      this.fetchUserForConnectedWallet(mlmType);
      return;
    }

    const parsedUser: UserInfo = JSON.parse(storedUser);
    const walletAddresses = await this.connectedWallet.api.getUsedAddresses();

    if (walletAddresses.includes(parsedUser.walletAddress.hex)) {
      this[userField] = parsedUser;
    } else {
      this[userField] = undefined;
      localStorage.removeItem(storageKey);
    }
  };

  private getStorageKey = (mlmType: MlmType) => {
    return mlmType === "Kwarxs" ? storageKeys.kwarxsUser : storageKeys.nfteamUser;
  };

  private getUserField = (mlmType: MlmType) => {
    return mlmType === "Kwarxs" ? "_kwarxsUser" : "_nfteamUser";
  };
}
