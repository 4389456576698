import { useAuthContext } from "../../context/AuthContext";
import { Button } from "../button";
import { Modal } from "../Modal";
import styles from "./index.module.scss";
import { useToastMessage } from "../../hooks/useToastMessage";
import { getConfig } from "../../config";

type BlockpassModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

interface BlockpassInstance {
  startKYCConnect: () => void;
}

interface BlockpassKYCConnectConstructor {
  new (clientId: string): BlockpassInstance;
}

declare global {
  interface Window {
    BlockpassKYCConnect: BlockpassKYCConnectConstructor;
  }
}

/**
 * A modal that guides the user through Blockpass verification.
 */
export const BlockpassModal = ({ isOpen, onClose }: BlockpassModalProps) => {
  const { kwarxsUser } = useAuthContext();
  const toast = useToastMessage();
  const config = getConfig();

  const onVerifyClick = () => {
    if (!kwarxsUser) {
      toast.error(
        "Please connect your wallet and sign up before beginning the Blockpass verification process."
      );
      return;
    }

    const blockpassUrl = new URL("https://verify-with.blockpass.org");
    blockpassUrl.searchParams.append("clientId", config.blockpassClientId);
    blockpassUrl.searchParams.append("refId", kwarxsUser.walletAddress.bech32);
    window.open(blockpassUrl, "_blank");
  };

  return (
    <Modal showModal={isOpen} onClose={onClose}>
      <div className={styles.modalContent}>
        <h2>Verify With Blockpass</h2>
        <p>
          Before purchasing an NFT, it is necessary to verify your identity for
          KYC purposes.
        </p>
        <p>
          You'll be directed to a separate Blockpass verification page. Once you
          complete the verification process, and have been approved, please try
          purchasing the NFT again.
        </p>
        <Button onClick={onVerifyClick}>Verify with Blockpass</Button>
      </div>
    </Modal>
  );
};

export default BlockpassModal;
